$icon-font-path: "bootstrap-sass/assets/fonts/bootstrap/";
@import "_variables";

@import "bootstrap-sass/assets/stylesheets/bootstrap/variables";

@import "bootstrap-sass/assets/stylesheets/bootstrap/mixins";
@import "bootstrap-sass/assets/stylesheets/bootstrap/normalize";
@import "bootstrap-sass/assets/stylesheets/bootstrap/print";
@import "bootstrap-sass/assets/stylesheets/bootstrap/glyphicons";

@import "bootstrap-sass/assets/stylesheets/bootstrap/scaffolding";
@import "bootstrap-sass/assets/stylesheets/bootstrap/type";
@import "bootstrap-sass/assets/stylesheets/bootstrap/code";
@import "bootstrap-sass/assets/stylesheets/bootstrap/grid";
@import "bootstrap-sass/assets/stylesheets/bootstrap/tables";
@import "bootstrap-sass/assets/stylesheets/bootstrap/forms";
@import "bootstrap-sass/assets/stylesheets/bootstrap/buttons";

@import "bootstrap-sass/assets/stylesheets/bootstrap/component-animations";
@import "bootstrap-sass/assets/stylesheets/bootstrap/dropdowns";
@import "bootstrap-sass/assets/stylesheets/bootstrap/button-groups";
@import "bootstrap-sass/assets/stylesheets/bootstrap/input-groups";
@import "bootstrap-sass/assets/stylesheets/bootstrap/navs";
@import "bootstrap-sass/assets/stylesheets/bootstrap/navbar";
@import "bootstrap-sass/assets/stylesheets/bootstrap/breadcrumbs";
@import "bootstrap-sass/assets/stylesheets/bootstrap/pagination";
@import "bootstrap-sass/assets/stylesheets/bootstrap/pager";
@import "bootstrap-sass/assets/stylesheets/bootstrap/labels";
@import "bootstrap-sass/assets/stylesheets/bootstrap/badges";
@import "bootstrap-sass/assets/stylesheets/bootstrap/jumbotron";
@import "bootstrap-sass/assets/stylesheets/bootstrap/thumbnails";
@import "bootstrap-sass/assets/stylesheets/bootstrap/alerts";
@import "bootstrap-sass/assets/stylesheets/bootstrap/progress-bars";
@import "bootstrap-sass/assets/stylesheets/bootstrap/media";
@import "bootstrap-sass/assets/stylesheets/bootstrap/list-group";
@import "bootstrap-sass/assets/stylesheets/bootstrap/panels";
@import "bootstrap-sass/assets/stylesheets/bootstrap/responsive-embed";
@import "bootstrap-sass/assets/stylesheets/bootstrap/wells";
@import "bootstrap-sass/assets/stylesheets/bootstrap/close";

@import "bootstrap-sass/assets/stylesheets/bootstrap/modals";

@import "bootstrap-sass/assets/stylesheets/bootstrap/utilities";
@import "bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities";

@import "_bootswatch";

select.form-control, textarea.form-control {
  background: $gray-dark;
}

.form-control[readonly] {
  color: $gray;
  background: $gray-darker;
}

@font-face {
  font-family: 'Aero Matics';
  src: url('../fonts/AeroMaticsRegular.woff2') format('woff2'),
       url('../fonts/AeroMaticsRegular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
